import {
  carouselAnimationBouncy,
  carouselAnimationDefault,
  carouselAnimationElegant,
  carouselAnimationSlow,
  carouselAnimationSuperSlow,
} from '@/animations';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { CarouselContext, CarouselItem } from '.';

const CarouselWrapper = ({
  items,
  dragWidth,
  dragHeight,
  gap,
  animationStyle = 'default',
  columnNum,
  crop,
  loop,
  variant,
  ...props
}) => {
  const length = Math.floor((items.length - 1) / columnNum);
  const { currItem, setCurrItem, isClickable, htmlDir } = useContext(CarouselContext);
  const [isDragging, setIsDragging] = useState(false);
  const slideWidth = dragWidth + (gap || 0);

  const transition = {
    default: carouselAnimationDefault,
    elegant: carouselAnimationElegant,
    bouncy: carouselAnimationBouncy,
    slow: carouselAnimationSlow,
    superSlow: carouselAnimationSuperSlow,
  };

  const carouselItems = loop ? [...items, ...items, ...items] : [...items, ...items];

  const handleItemClick = (index) => {
    !loop && isClickable && setCurrItem(index);
  };

  const renderItems = carouselItems.map((val, i) => {
    return (
      <CarouselItem
        key={`carouselItem_${i}`}
        item={val}
        index={i}
        width={dragWidth}
        height={dragHeight}
        slideWidth={slideWidth}
        columnNum={columnNum}
        length={length}
        mappedLength={carouselItems.length}
        animationStyle={animationStyle}
        variant={variant}
        loop={loop}
        onClick={() => handleItemClick(i)}
        isDragging={isDragging}
      />
    );
  });

  const startDrag = () => {
    setIsDragging(true);
  };

  const endDrag = (event, info) => {
    const { velocity } = info;
    const vel = velocity.x;
    const fastEnough = Math.abs(vel) > 5;

    if (fastEnough && typeof currItem === 'number') {
      const delta = vel > 0 ? (htmlDir === 'ltr' ? -1 : 1) : htmlDir === 'ltr' ? 1 : -1;
      const next = currItem + delta;

      if (next !== currItem) {
        const croppedVal = loop ? next : Math.max(0, Math.min(length, next));

        setCurrItem(croppedVal);
      }
    }

    setIsDragging(false);
  };

  let x = 0;
  if (typeof currItem === 'number') {
    x = currItem * slideWidth * (htmlDir === 'ltr' ? -1 : 1);
  }

  return (
    <div className={classNames('carousel-canvas flex flex-initial shrink-0', crop && 'overflow-hidden')}>
      <motion.div
        drag={length > 0 ? 'x' : false}
        onDragEnd={endDrag}
        onDragStart={startDrag}
        animate={{ x }}
        dragConstraints={{ left: x, right: x, top: 0, bottom: 0 }}
        className={classNames('carousel-wrapper flex flex-row items-start', {
          dragging: isDragging,
        })}
        style={{ gap: `${gap}px` }}
        transition={transition[animationStyle]}
        {...props}
      >
        {renderItems}
      </motion.div>
    </div>
  );
};

export default CarouselWrapper;
